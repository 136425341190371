@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
@keyframes slowDown {
  0% {
    top: -88px; }
  100% {
    top: 0; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-30px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes fadeInFromRight {
  0% {
    opacity: 0;
    transform: translateX(30px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

#top {
  /* Arrows */
  /* Dots */ }
  #top .p-mainVisual {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden; }
    @media screen and (max-width: 868px) {
      #top .p-mainVisual {
        height: 507px; } }
    @media screen and (max-width: 568px) {
      #top .p-mainVisual {
        height: 450px; } }
    #top .p-mainVisual__bg {
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative; }
      @media screen and (max-width: 768px) {
        #top .p-mainVisual__bg {
          width: calc(100% - 30px); } }
    #top .p-mainVisual__swipe {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: calc(436 / 1063 * 100%);
      background-color: #FFFFFF;
      line-height: 0; }
      @media screen and (max-width: 768px) {
        #top .p-mainVisual__swipe {
          top: 23px;
          transform: none;
          width: 296px; } }
      #top .p-mainVisual__swipe-inner {
        width: 100%;
        height: 100%; }
      #top .p-mainVisual__swipe-wrap {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: -125px; }
        @media screen and (max-width: 768px) {
          #top .p-mainVisual__swipe-wrap {
            right: -30px; } }
      #top .p-mainVisual__swipe-item {
        width: 100%;
        height: 23.6vw;
        max-height: 500px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover; }
        @media screen and (max-width: 768px) {
          #top .p-mainVisual__swipe-item {
            height: 205px; } }
    #top .p-mainVisual__catch {
      position: absolute;
      top: 37%;
      left: 0;
      right: 0;
      margin: auto;
      max-width: 466px;
      width: 100%;
      color: #FFFFFF;
      text-align: center; }
      @media screen and (max-width: 768px) {
        #top .p-mainVisual__catch {
          top: 35.5%; } }
      @media screen and (max-width: 568px) {
        #top .p-mainVisual__catch {
          max-width: 300px; } }
      #top .p-mainVisual__catch-heading {
        font-size: 60px;
        margin-bottom: 18px;
        color: #fff;
        display: inline;
        line-height: 1.7em;
        font-family: '源ノ明朝', serif !important;
        letter-spacing: 0.08em; }
        @media screen and (max-width: 768px) {
          #top .p-mainVisual__catch-heading {
            font-size: 40px; } }
        @media screen and (max-width: 568px) {
          #top .p-mainVisual__catch-heading {
            font-size: 40px; } }
        #top .p-mainVisual__catch-heading small {
          font-size: 32px; }
          @media screen and (max-width: 1024px) {
            #top .p-mainVisual__catch-heading small {
              font-size: 26px; } }
          @media screen and (max-width: 768px) {
            #top .p-mainVisual__catch-heading small {
              font-size: 22px; } }
      #top .p-mainVisual__catch-desc {
        font-size: 20px;
        line-height: 1.5;
        color: #fff;
        display: inline-block;
        padding: 5px 10px;
        text-align: left;
        text-shadow: 1px 1.5px 3px #333333;
        letter-spacing: 0.06em; }
        @media screen and (max-width: 768px) {
          #top .p-mainVisual__catch-desc {
            font-size: 16px; } }
        @media screen and (max-width: 768px) {
          #top .p-mainVisual__catch-desc {
            font-size: 14px; } }
    #top .p-mainVisual .p-catch-heading {
      padding: 3px; }
    #top .p-mainVisual__scroll {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 105px;
      right: -55px;
      transform: rotate(90deg); }
      @media screen and (max-width: 768px) {
        #top .p-mainVisual__scroll {
          bottom: 125px;
          right: -68px; } }
      #top .p-mainVisual__scroll-txt {
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0.05em;
        display: inline-block;
        margin-right: 12px; }
      #top .p-mainVisual__scroll-bar {
        display: inline-block;
        width: 100px;
        height: 2px;
        background-color: #CCCCCC;
        position: relative;
        overflow: hidden; }
        #top .p-mainVisual__scroll-bar:before {
          content: '';
          width: 50%;
          height: 2px;
          position: absolute;
          top: 0;
          left: -50%;
          display: inline-block;
          background-color: #073387; }
    #top .p-mainVisual .p-shadow {
      text-shadow: 1px 1.5px 3px #333333;
      letter-spacing: 0.17em; }
  @media screen and (max-width: 768px) {
    #top .p-pc-only {
      display: none; } }
  #top .p-tab-only {
    display: none; }
    @media screen and (max-width: 768px) {
      #top .p-tab-only {
        display: block; } }
  #top #wrapper {
    padding: 0 0; }
    @media screen and (max-width: 1168px) {
      #top #wrapper {
        padding: 60px 0 0; } }
  #top #newslist {
    padding-bottom: 0; }
    #top #newslist .c-newslist {
      display: block !important; }
      @media screen and (max-width: 768px) {
        #top #newslist .c-newslist__wrap {
          background-color: #F7F7F7;
          padding: 15px 0 30px; } }
      @media screen and (max-width: 768px) {
        #top #newslist .c-newslist dl dt {
          margin-bottom: 5px; } }
      #top #newslist .c-newslist dl dd {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
  #top .p-about .l-col2__detail {
    position: relative; }
  #top .p-reason .l-col2__detail {
    position: relative; }
    #top .p-reason .l-col2__detail:before {
      content: '';
      display: block;
      background: url("/inc/image/top/img_loader.jpg") no-repeat center/contain;
      width: 968px;
      height: 519px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      z-index: -1; }
      @media screen and (max-width: 768px) {
        #top .p-reason .l-col2__detail:before {
          width: 658px;
          height: 357.7px; } }
      @media screen and (max-width: 568px) {
        #top .p-reason .l-col2__detail:before {
          width: 484px;
          height: 259.5px; } }
  #top .p-small-dot_01 {
    position: relative; }
  #top .p-small-dot_02 {
    position: relative; }
  #top .slick-slide img {
    display: block;
    width: 65vw;
    height: auto;
    border-radius: 10px;
    margin: 20px;
    margin-top: 0; }
    @media screen and (max-width: 568px) {
      #top .slick-slide img {
        width: 100vw; } }
  #top .slick-prev,
  #top .slick-next {
    z-index: 1000;
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none; }
    #top .slick-prev:hover, #top .slick-prev:focus,
    #top .slick-next:hover,
    #top .slick-next:focus {
      outline: none;
      background: transparent;
      color: transparent; }
      #top .slick-prev:hover:before, #top .slick-prev:focus:before,
      #top .slick-next:hover:before,
      #top .slick-next:focus:before {
        opacity: 1; }
    #top .slick-prev.slick-disabled:before,
    #top .slick-next.slick-disabled:before {
      opacity: 0.25; }
    #top .slick-prev:before,
    #top .slick-next:before {
      font-family: "slick";
      font-size: 25px;
      line-height: 1;
      color: #073387;
      opacity: 0.75;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
  #top .slick-prev {
    left: 16.5%; }
    @media screen and (max-width: 568px) {
      #top .slick-prev {
        left: 6.5%; } }
    [dir="rtl"] #top .slick-prev {
      left: auto;
      right: 16.5%; }
      @media screen and (max-width: 568px) {
        [dir="rtl"] #top .slick-prev {
          right: 6.5%; } }
    #top .slick-prev:before {
      content: "←"; }
      [dir="rtl"] #top .slick-prev:before {
        content: "→"; }
  #top .slick-next {
    right: 17%; }
    @media screen and (max-width: 568px) {
      #top .slick-next {
        right: 6%; } }
    [dir="rtl"] #top .slick-next {
      left: 17%;
      right: auto; }
      @media screen and (max-width: 568px) {
        [dir="rtl"] #top .slick-next {
          left: 6%; } }
    #top .slick-next:before {
      content: "→"; }
      [dir="rtl"] #top .slick-next:before {
        content: "←"; }
  #top .slick-dotted.slick-slider {
    margin-bottom: 30px; }
  #top .slick-dots {
    position: absolute;
    bottom: 75px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%; }
    @media screen and (max-width: 768px) {
      #top .slick-dots {
        bottom: -10px; } }
    #top .slick-dots li {
      position: relative;
      display: inline-block;
      height: 20px;
      width: 20px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer; }
      #top .slick-dots li button {
        border: 0;
        background: transparent;
        display: block;
        height: 20px;
        width: 20px;
        outline: none;
        line-height: 0px;
        font-size: 0px;
        color: transparent;
        padding: 5px;
        cursor: pointer; }
        #top .slick-dots li button:hover, #top .slick-dots li button:focus {
          outline: none; }
          #top .slick-dots li button:hover:before, #top .slick-dots li button:focus:before {
            opacity: 1; }
        #top .slick-dots li button:before {
          position: absolute;
          top: 0;
          left: 0;
          content: "•";
          width: 20px;
          height: 20px;
          font-family: "slick";
          font-size: 6px;
          line-height: 20px;
          text-align: center;
          color: black;
          opacity: 0.25;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale; }
          @media screen and (max-width: 1024px) {
            #top .slick-dots li button:before {
              font-size: 12px; } }
      #top .slick-dots li.slick-active button:before {
        color: black;
        opacity: 0.75; }
  #top .p-mv {
    width: 100%;
    height: calc(100vh - 94px);
    position: relative;
    top: 0px;
    left: 0;
    z-index: 1;
    overflow: hidden; }
    #top .p-mv::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: #FFFFFF;
      opacity: .7;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1; }
    #top .p-mv__video {
      width: 100%;
      height: 100%;
      display: block;
      line-height: 1;
      object-fit: cover;
      object-position: center;
      font-family: "object-fit: cover; object-position: center;"; }
    #top .p-mv__copy {
      position: absolute;
      top: calc(50% + 88px / 2);
      left: 26%;
      transform: translate(-50%, -50%);
      z-index: 2;
      width: 100%; }
      @media screen and (max-width: 1168px) {
        #top .p-mv__copy {
          top: calc(30% + 60px / 2); } }
      @media screen and (max-width: 768px) {
        #top .p-mv__copy {
          left: 35%; } }
      @media screen and (max-width: 568px) {
        #top .p-mv__copy {
          left: 42%; } }
      #top .p-mv__copy-img {
        max-width: 370px;
        width: 100%;
        margin: 0 auto 10px; }
        @media screen and (max-width: 768px) {
          #top .p-mv__copy-img {
            max-width: 360px;
            margin-bottom: 0; } }
        @media screen and (max-width: 568px) {
          #top .p-mv__copy-img {
            max-width: 280px; } }
        #top .p-mv__copy-img img {
          width: 100%;
          display: block;
          line-height: 1;
          opacity: 0; }
        #top .p-mv__copy-img-middle {
          margin-bottom: -10px; }
          @media screen and (max-width: 568px) {
            #top .p-mv__copy-img-middle {
              margin-bottom: -5px; } }
          #top .p-mv__copy-img-middle.is-animate {
            animation: fadeIn 3s ease 1.5s 1 normal forwards; }
      #top .p-mv__copy-desc {
        font-size: 25px;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 1.2;
        text-align: center;
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
        margin: 0; }
        @media screen and (max-width: 768px) {
          #top .p-mv__copy-desc {
            font-size: 22px; } }
        @media screen and (max-width: 568px) {
          #top .p-mv__copy-desc {
            font-size: 18px; } }
        #top .p-mv__copy-desc.is-animate {
          opacity: 0;
          animation: fadeIn 1s ease 2s 1 normal forwards; }
